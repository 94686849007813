import {
  H2,
} from '@blueprintjs/core'

import { Layout } from 'pages'

import ManagePasskeys from './ManagePasskeys'

const Settings: React.FC = () => {
  return (
    <Layout.Primary breadcrumbs={[{ text: 'Settings' }]}>
      {/*
      Future:
      - email
      - notifications
      - close account
      */}
      <H2>Passkeys</H2>
      <ManagePasskeys />
    </Layout.Primary>
  )
}

export default Settings
