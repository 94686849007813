const exponents = {
  USD: 2,
  XXX: 0,
  XTS: 0,
}
interface Props {
  amount: Money
}
interface Helpers {
  multiply: (amount: Money, count: number) => Money
}
const Money: React.FC<Props>&Helpers = ({ amount }) => {
  const exponent = exponents[amount.currency]
  const factor = Math.pow(10, exponent)
  const majorUnit = parseInt(amount.amount, 10) / factor
  const formatted = Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: amount.currency,
  }).format(majorUnit)
  return <>{formatted}</>
}

Money.multiply = (amount: Money, count: number): Money => {
  const number = parseInt(amount.amount, 10)
  const product = number * count
  return { amount: product+'', currency: amount.currency }
}

export default Money
