import { NonIdealState } from '@blueprintjs/core'
import { Link, Redirect } from 'wouter'

import { useApiData } from 'hooks'
import { Layout } from 'pages'

const SignedInHome: React.FC = () => {
  const orgs = useApiData('/app/getMyOrgs', {})
  if (!orgs) {
    return <NonIdealState
      icon="search"
      title="Loading..."
    />
  }
  if (orgs.length === 0) {
    return <Redirect to="/org/create" replace />
  } else if (orgs.length === 1) {
    const org = orgs[0]
    return <Redirect to={`/org/${org.id}`} replace />
  }
  return (
    <Layout.Primary id="SignedInHome">
      <h2>Organizations</h2>
      <ul>
        {orgs.map(org => (
          <li key={org.id}>
            <Link to={`/org/${org.id}`} className="OrgLink">
              {org.name}
            </Link>
          </li>
        ))}
      </ul>
    </Layout.Primary>
  )
}

export default SignedInHome
