import { MouseEvent, useEffect, useState } from 'react'
import { useLocation } from 'wouter'
import {
  Alert,
  Button,
  H2,
  HTMLTable,
  NonIdealState,
  Spinner,
} from '@blueprintjs/core'

import { PasskeyIcon } from 'components'
import { useApp, useApiData } from 'hooks'
import { snapAuth, toast } from 'utilities'

const ManagePasskeys: React.FC = () => {
  const [isAdding, setIsAdding]  = useState(false)
  const [authToken, setAuthToken] = useState<string|null>(null)
  const [, setLocation] = useLocation()
  const { post } = useApp()

  const credentials = useApiData('/app/getMyCredentials', {})
  const me = useApiData('/app/me', {})

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    if (query.has('success')) {
      toast.success('Credential added')
      setLocation(window.location.pathname, { replace: true })
    }
  }, [])

  if (!me || !credentials) {
    return <NonIdealState title="Loading" icon={<Spinner />} />
  }

  const closeDialog = () => {
    setAuthToken(null)
    setIsAdding(false)
  }

  const doAuth = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const res = await snapAuth.startAuth(me) // id fits, yay
    if (res.ok) {
      setAuthToken(res.data.token)
    } else {
      // TODO: tighten message (res.error)
      toast.warning('Passkey setup canceled')
      closeDialog()
    }
  }

  const addPasskey = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    const cred = await snapAuth.startRegister({ name: me.email })

    if (cred.ok) {
      await post('/app/addCredential', { authToken, credToken: cred.data.token })
      // HACK: hard refresh since there's no clear state/reload data hook
      // Trigger a hard refresh, set query to persist toast
      window.location.href = (window.location.pathname + '?success')
      // closeDialog()
    }
  }

  const onConfirm = authToken === null ? doAuth : addPasskey
  const confirmButtonText = authToken === null ? 'Authenticate' : 'Add passkey'
  const body = authToken === null
    ? <p>To add a new passkey, first re-authenticate using an <em>existing</em> one:</p>
    : <p>Great! Now use your <em>new</em> credential:</p>

  return (
    <>
      <HTMLTable interactive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Backup Status</th>
            <th>Created</th>
            {/* <th>Actions</th> */}
          </tr>
        </thead>
        <tbody>
          {credentials.data.map(c => (
            <tr key={c.id}>
              <td>{c.name}</td>
              <td>{toHumanBackup(c.backup)}</td>
              <td>{new Date(c.createdAt * 1000).toLocaleString()}</td>
              {/* <td><Button intent="danger" icon="delete" disabled minimal>Delete...</Button></td> */}
            </tr>
          ))}
        </tbody>
      </HTMLTable>

      <Button type="button" icon={<PasskeyIcon />} onClick={() => setIsAdding(true)} intent="primary">Add Passkey</Button>
      <Alert
        canEscapeKeyCancel
        canOutsideClickCancel={authToken === null} // only on step 1
        cancelButtonText="Cancel"
        icon={<PasskeyIcon intent="success" size={40} />}
        intent="success"
        isOpen={isAdding}
        onCancel={close}
        {...{confirmButtonText, onConfirm}}
      >
        <H2>Add a passkey</H2>
        {body}
      </Alert>

    </>
  )
}

const toHumanBackup = (backup: Entities.CredentialBackupState): React.ReactNode => {
  switch (backup) {
    case 'backed-up': return 'Backed up'
    case 'not-backed-up': return 'Not backed up'
    case 'unavailable': return 'Unavailable'
  }
}

export default ManagePasskeys
