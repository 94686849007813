import { useEffect, useState } from 'react'
import { AuthResponse } from '@snapauth/sdk'
import { Button } from '@blueprintjs/core'

import { EmailInput, PasskeyIcon } from 'components'
import { useApp } from 'hooks'
import { Layout } from 'pages'
import { snapAuth, toast } from 'utilities'

const SignInUp: React.FC = () => {
  const app = useApp()
  const [signInMode, setSignInMode] = useState(true)

  const handleSignIn = async (auth: AuthResponse) => {
    if (auth.ok) {
      const signInResult = await app.post('/app/signin', { token: auth.data.token })
      app.signIn(signInResult.accessToken)
    } else {
      console.error('Sign in API failed', auth)
    }
  }

  useEffect(() => {
    snapAuth.handleAutofill(handleSignIn)
  }, [])

  const submit = async (e: React.FormEvent) => {
    e.preventDefault()
    const fd = new FormData(e.target as HTMLFormElement)
    const email = fd.get('email')!.toString()
    if (signInMode) {
      startSignIn(email)
    } else {
      const available = await app.post('/app/registrationCheck', { email })
      if (available) {
        signUp(email)
      } else {
        startSignIn(email)
      }
    }
  }

  const signUp = async (email: string) => {
    const registration = await snapAuth.startRegister({ handle: email, name: email })
    if (registration.ok) {
      const regInfo = await app.post('/app/register', { email, token: registration.data.token })
      toast.success('Account registered')
      app.signInWithoutRedirecting(regInfo.accessToken)
    }
  }

  const startSignIn = async (email: string) => {
    handleSignIn(await snapAuth.startAuth({ handle: email }))
  }

  const title = signInMode ? 'Sign In to SnapAuth' : 'Sign Up for SnapAuth'

  return (
    <Layout.Dialog title={title}>
      <form onSubmit={submit}>
        <EmailInput required />
        <Button type="submit" intent="primary" rightIcon={<PasskeyIcon />}>Sign {signInMode ? 'In' : 'Up'}</Button>
        <Button type="button" onClick={() => setSignInMode(m => !m)} minimal>
          {signInMode ? 'Need to register?' : 'Already have an account?'}
        </Button>
      </form>
    </Layout.Dialog>
  )
}

export default SignInUp
