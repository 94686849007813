import { useContext } from 'react'
import { useLocation } from 'wouter'

import { API_HOST } from 'config'
import { AppContext } from 'contexts/AppContext'

/**
 * Wrapper for the auth context. Avoids letting components access the API token
 * and having to deal with the React/API token handoff.
 */
const useApp = () => {
  const { state, dispatch } = useContext(AppContext)
  const [, updateLocation] = useLocation()

  const isAuthenticated = state.apiToken !== null
  // integrate into post?? it's kinda situational even if the majority case
  const signIn = (token: string) => {
    signInWithoutRedirecting(token)
    updateLocation('/')
  }
  const signInWithoutRedirecting = (token: string) => {
    dispatch({ type: 'SIGN_IN', token })
  }
  const signOut = () => {
    dispatch({ type: 'SIGN_OUT' })
    updateLocation('/signin')
  }

  return {
    isAuthenticated,
    post: async (path: string, data: {[key: string]: any}) => {
      if (path.charAt(0) !== '/') {
        throw new Error('Path must start with leading slash')
      }
      const headers = new Headers({
        'Content-type': 'application/json',
      })
      if (isAuthenticated) {
        headers.append('Authorization', `Bearer ${state.apiToken}`)
      }
      const response = await fetch(API_HOST + path, {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
      })
      if (response.ok) {
        return (await response.json()).result
      } else if (response.status === 401) {
        console.debug('401, signing out')
        signOut()
      } else {
        // do what here?
      }
    },
    signIn,
    signInWithoutRedirecting,
    signOut,
  }
}

export default useApp
