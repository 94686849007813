import { useEffect } from 'react'
import {
  Switch,
  Redirect,
  Route,
} from 'wouter'

import { WithParams } from 'App'
import { ActiveLink } from 'components'
import { Layout } from 'pages'
import { useApiData } from 'hooks'
import { toast } from 'utilities'

import Billing from './Billing'
import Env from './Env'
import Members from './Members'


interface Props {
  orgId: OrgId
}
const Org: React.FC<Props> = ({ orgId }) => {
  const org = useApiData('/app/getOrgById', { orgId })

  // Stripe redirect after billing: configured by backend
  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    if (query.has('card-added')) {
      toast.success('Card added!')
    } else if (query.has('card-not-added')) {
      toast.warning('Card not added')
    }
  }, [])



  return (
    <Layout.Primary
      breadcrumbs={[ { text: org?.name } ]}
      id="Org"
      secondLevelNav={<>
        <ActiveLink href="/env" minimal wildcard>Environments</ActiveLink>
        <ActiveLink href="/billing" minimal>Billing</ActiveLink>
        <ActiveLink href="/members" minimal>Members</ActiveLink>
      </>}
    >
      <Switch>
        <Route path="/billing" component={() => <Billing orgId={orgId} />} />
        <Route path="/members" component={() => <Members orgId={orgId} />} />
        <Route path="/env/:envId" component={WithParams(Env)} nest />
        <Route component={() => <PickEnv orgId={orgId} />} />
      </Switch>
    </Layout.Primary>
  )
}

const PickEnv: React.FC<Props> = ({ orgId }) => {
  const envIds = useApiData('/app/getEnvironmentIdsForOrg', { orgId })
  if (!envIds) {
    // Gotta wait for data to load here
    return null;
  }
  if (envIds.length === 0) {
    return <Redirect to="/createEnv" />
  }

  return <Redirect to={`/env/${envIds[0]}`} />
}

export default Org
