import { OverlayToaster } from '@blueprintjs/core'
import { SDK } from '@snapauth/sdk'

import { ANALYTICS_ENABLED, API_HOST, SDK_PUBLISHABLE_KEY } from 'config'

// WARNING: this triggers a react 17 warning.
// see https://github.com/palantir/blueprint/issues/5212
const toaster = OverlayToaster.create()
export const toast = {
  danger: (message: string) => toaster.show({ message, intent: 'danger' }),
  primary: (message: string) => toaster.show({ message, intent: 'primary' }),
  success: (message: string) => toaster.show({ message, intent: 'success' }),
  warning: (message: string) => toaster.show({ message, intent: 'warning' }),
  stick: {
    danger: (message: string) => toaster.show({ message, intent: 'danger', timeout: 0 }),
    primary: (message: string) => toaster.show({ message, intent: 'primary', timeout: 0 }),
    success: (message: string) => toaster.show({ message, intent: 'success', timeout: 0 }),
    warning: (message: string) => toaster.show({ message, intent: 'warning', timeout: 0 }),
  }
}

export const snapAuth = new SDK(SDK_PUBLISHABLE_KEY, API_HOST)

// Analytics

type StandardEventName =
  | 'pageview'
  | 'Outbound Link: Click'
export const trackEvent = (event: StandardEventName|string, props?: DictOf<string>) => {
  if (!ANALYTICS_ENABLED) {
    return
  }
  // This inlines the logic of Plausible's (quite simple) script. Avoids an
  // external dependency and allows redacting the referrer, not just the URL
  // https://plausible.io/docs/events-api
  fetch('https://plausible.io/api/event', {
    method: 'POST',
    headers: { 'Content-type': 'text/plain' },
    body: JSON.stringify({
      // Uses short names inferred from their script
      d: 'snapauth.app', // domain = script data-domain
      r: redact(window.document.referrer), // referrer
      n: event, // name
      u: redact(window.location.href), // url
      props,
    })
  })
}
export const trackPageview = () => trackEvent('pageview')

const redact = (url: string): string => {
  return url.replace(/([a-z]{3})_[A-z0-9]{24}/g, ':$1Id')
}
