import { useEffect } from 'react'
import { Classes } from '@blueprintjs/core'
import { useLocation, Redirect, Route, Switch } from 'wouter'

import { useApp, useMediaQuery } from 'hooks'
import { trackPageview } from 'utilities'
import * as Pages from 'pages'

/**
 * HoC to splat wouter's `params` directly into the component's props
 */ // @ts-expect-error Not worth fighting with {id:AnyId}
export const WithParams = (Component) => (arg) => <Component {...arg.params} />

// TODO: make this all less stringy
// Note: the order matters - the first match wins. Empty string is catch-all.
const SignedInRoutes: React.FC = () => (
  <Switch>
    <Route path="/confirm/email" component={Pages.ConfirmEmail} />
    <Route path="/org/create" component={Pages.CreateOrg} />
    <Route path="/org/join/:inviteId" component={WithParams(Pages.AcceptInvite)} />
    <Route path="/org/:orgId/createEnv" component={WithParams(Pages.CreateEnv)} />
    <Route path="/org/:orgId" component={WithParams(Pages.Org)} nest />
    <Route path="/settings" component={Pages.Settings} />
    <Route path="/" component={Pages.SignedInHome} />
    {/* 404 catch all redirect... should probably be actual 404 page */}
    <Route path="" component={() => <Redirect to="/" replace />} />
  </Switch>
)

const SignedOutRoutes: React.FC = () => (
  <Switch>
    <Route path="/confirm/email" component={Pages.ConfirmEmail} />
    <Route path="/register" component={Pages.SignInUp} />
    <Route path="/signin" component={Pages.SignInUp} />
    <Route path="/org/join/:inviteId" component={WithParams(Pages.AcceptInvite)} />
    <Route path="" component={() => <Redirect to="/signin" replace />} />
  </Switch>
)

const App: React.FC = () => {
  const darkMode = useMediaQuery('(prefers-color-scheme: dark)')
  useEffect(() => {
    document.querySelector('html')!.className = darkMode ? Classes.DARK : ''
  }, [darkMode])

  const app = useApp()
  // Fire analytics when SPA location changes
  const [l, ] = useLocation()
  useEffect(trackPageview, [l])

  // Steer users in and out of the app depending on auth state
  if (app.isAuthenticated) {
    return <SignedInRoutes />
  } else {
    return <SignedOutRoutes />
  }
}

export default App
