import { useEffect, useState } from 'react'
import { Button } from '@blueprintjs/core'
import { AuthResponse } from '@snapauth/sdk'

import { EmailInput } from 'components'
import { useApp } from 'hooks'
import { Layout } from 'pages'
import { snapAuth, toast } from 'utilities'

/**
 * This presents a sign in or up UI for familiarity, but in reality will still
 * detect if you've already got an account and intelligently do the right
 * thing.
 */
interface Props {
  invite: Entities.Invite
}
const AcceptInviteSignedOut: React.FC<Props> = ({ invite }) => {
  const app = useApp()
  const [signInMode, setSignInMode] = useState(false)

  useEffect(() => {
    snapAuth.handleAutofill(signIn)
  }, [])

  const startSignIn = async (email: string) => {
    const auth = await snapAuth.startAuth({ handle: email })
    signIn(auth)
  }
  const signIn = async (auth: AuthResponse) => {
    if (auth.ok) {
      const signInResult = await app.post('/app/signin', { token: auth.data.token })
      app.signInWithoutRedirecting(signInResult.accessToken)
    }
  }
  const signUp = async (email: string) => {
    const registration = await snapAuth.startRegister({ handle: email, name: email })
    if (registration.ok) {
      const regInfo = await app.post('/app/register', { email, token: registration.data.token })
      toast.success('Account registered')
      app.signInWithoutRedirecting(regInfo.accessToken)
    }
  }

  const setup = async (e: React.FormEvent) => {
    e.preventDefault()
    const fd = new FormData(e.target as HTMLFormElement)
    const email = fd.get('email')!.toString()
    if (signInMode) {
      startSignIn(email)
    } else {
      const available = await app.post('/app/registrationCheck', { email })
      if (available) {
        signUp(email)
      } else {
        startSignIn(email)
      }
    }
  }

  return (
    <Layout.Dialog title="Accept Invitation">
      <p>Sign {signInMode ? 'in' : 'up' } to join <em>{invite.orgName}</em></p>

      <form onSubmit={setup}>
        <EmailInput defaultValue={invite.recipientEmail} required />
        <Button type="submit" intent="primary">Sign {signInMode ? 'In' : 'Up'}</Button>
        <Button type="button" onClick={() => setSignInMode(m => !m)} minimal>
          {signInMode ? 'Need to register?' : 'Already have an account?'}
        </Button>
      </form>

    </Layout.Dialog>
  )
}

export default AcceptInviteSignedOut
