import { useState } from 'react'
import {
  Button,
  Code,
  Collapse,
  Dialog,
  DialogBody,
  DialogFooter,
  HTMLTable,
  HTMLSelect,
  FormGroup,
} from '@blueprintjs/core'

import { Time } from 'components'
import { useApiData, useApp } from 'hooks'
import { toast } from 'utilities'

interface Props {
  envId: EnvId
}
const ApiKeys: React.FC<Props> = ({ envId }) => {
  const apiKeys = useApiData('/app/getApiKeys', { envId })
  const [dialogKey, setDialogKey] = useState<Entities.SecretKey|null>(null)

  if (!apiKeys) {
    return null
  }

  return (
    <>
      <HTMLTable striped>
        <thead>
          <tr>
            <th>Type</th>
            <th>Key</th>
            <th>Created</th>
            <th>Expires</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {apiKeys.publishable.map(pub => (
            <tr key={pub.value}>
              <td>Publishable</td>
              <td><Code>{pub.value}</Code></td>
              <td colSpan={3}>
                <Time unixtimeSeconds={pub.createdAt} />
              </td>
            </tr>
          ))}
          {apiKeys.secret.map(secret => (
            <SecretRow key={secret.id} secret={secret} openDialog={setDialogKey} />
          ))}
        </tbody>
      </HTMLTable>

      {dialogKey && <RollKeyDialog secret={dialogKey} close={() => setDialogKey(null)} />}
    </>
  )
}

interface SecretRowProps {
  secret: Entities.SecretKey
  openDialog: (secret: Entities.SecretKey) => void
}
const SecretRow: React.FC<SecretRowProps> = ({ openDialog, secret }) => {
  const [showSecret, setShowSecret] = useState(false)
  const isExpired = secret.expiresAt && (new Date().getTime() > (secret.expiresAt * 1000))
  if (isExpired) {
    return (
      <tr>
        <td>Secret</td>
        <td></td>
        <td><Time unixtimeSeconds={secret.createdAt} /></td>
        <td colSpan={2}><em>Expired <Time unixtimeSeconds={secret.expiresAt!} /></em></td>
      </tr>
    )
  }
  return (
    <tr>
      <td>Secret</td>
      <td>
        <Collapse isOpen={showSecret}><Code>{secret.value}</Code></Collapse>
        {showSecret
          ? <Button icon="eye-off" onClick={() => setShowSecret(false)}>Hide Secret</Button>
          : <Button icon="eye-open" onClick={() => setShowSecret(true)}>Reveal Secret</Button>
        }
      </td>
      <td><Time unixtimeSeconds={secret.createdAt} /></td>
      <td>{secret.expiresAt && <Time unixtimeSeconds={secret.expiresAt} />}</td>
      <td>
        {secret.expiresAt
          ? <Button intent="danger" minimal type="button" onClick={() => openDialog(secret)}>Expire Now...</Button>
          : <Button type="button" intent="warning" minimal onClick={() => openDialog(secret)}>Roll...</Button>
        }
      </td>

    </tr>
  )
}

interface RKDProps {
  secret: Entities.SecretKey
  close: () => void
}
const RollKeyDialog: React.FC<RKDProps> = ({ close, secret }) => {
  const expireImmediately = secret.expiresAt !== null

  const [expiration, setExpiration] = useState<keyof typeof expirationOptions>('')
  const disabled = !expireImmediately && expiration === ''

  const { post } = useApp()
  const expireAt = async (interval: string) => {
    const result = await post('/app/apiKeys/expire', { secretKeyId: secret.id, interval })
    if (result) {
      toast.success('Key rolled')
      close()
      window.location.reload() // FIXME
    }
  }
  const roll = () => expireAt(expiration)
  const expire = () => expireAt('PT0S')

  const actions = <>
    <Button
      onClick={close}
      type="button"
    >Cancel</Button>
    <Button
      disabled={disabled}
      intent="danger"
      onClick={expireImmediately ? expire : roll}
      type="button"
    >{expireImmediately ? 'Expire Key Now' : 'Roll Key'}</Button>
  </>
  return (
    <Dialog
      title={expireImmediately ? "Immediately expire key" : "Roll API Key"}
      isOpen
      onClose={close}
      canEscapeKeyClose
      canOutsideClickClose
    >
      <DialogBody>
        {!expireImmediately && <p>"Rolling" a key will cause it to expire after the specified delay, and immediately generate a new key. This <strong>should</strong> be done if the key was published.</p>}

        <p><Code>{secret.value.substring(0, 10)}...{secret.value.slice(-3)}</Code></p>

        {expireImmediately ||
        <FormGroup label="Expiration" helperText="Once this key expires, it cannot be used for any API call" inline>
          <HTMLSelect value={expiration} onChange={(e) => setExpiration(e.target.value as keyof typeof expirationOptions)}>
            {Object.entries(expirationOptions).map(([k, v]) => <option value={k} key={k}>{v}</option>)}
          </HTMLSelect>
        </FormGroup>
        }
      </DialogBody>
      <DialogFooter actions={actions} />
    </Dialog>
  )
}

const expirationOptions = {
  '': 'Select an expiration date...',
  PT0S: 'Now',
  PT1H: 'In 1 hour',
  P1D: 'In 24 hours',
  P7D: 'In 7 days',
}

export default ApiKeys
