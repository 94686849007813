import { Classes, Intent } from '@blueprintjs/core'
import cn from 'classnames'

interface Props {
  intent?: Intent
  size?: number
}


// This inlines the SVG directly, with slight modifications (removal of most
// fill values, and a top level addition) to avoid having to add a pile of
// external dependencies to do a content include.
//
// Size=16 matches a) blueprint's own default and b) 1rem
// Intent: same semantics
const PasskeyIcon: React.FC<Props> = ({ intent = Intent.NONE, size = 16 }) => (
  <span className={cn(Classes.ICON, Classes.intentClass(intent))}>
    <svg height={size} viewBox="0 0 143 153" width={size} fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
      <path d="m-30-35h216v216h-216z" fill="none"/>
      <g fillRule="evenodd">
        <path d="m142.320007 61.790001c0 13.779999-8.480011 25.5-20.290008 29.779999l7.139999 11.829994-10.569992 13 10.569992 12.710007-17.039993 22.869995-12.01001-12.819992v-25.900009-22.559998c-10.679993-4.849999-18.149994-15.969994-18.149994-28.909996 0-17.400002 13.510002-31.510002 30.179993-31.510002 16.660004 0 30.170013 14.11 30.170013 31.510002zm-30.180008 4.82c4.020005 0 7.279999-3.400002 7.279999-7.599999 0-4.200004-3.259994-7.610008-7.279999-7.610008-4.020004 0-7.279998 3.400009-7.279998 7.610008-.009995 4.199997 3.259994 7.599999 7.279998 7.599999z" />
        <path d="m142.410004 61.879997c0 13.620003-8.25 25.230004-19.830002 29.670006l6.580002 11.839996-9.730011 13 9.730011 12.710007-17.029999 23.049988v-25.899994-32.770004-26.869995c4.019989 0 7.279999-3.410004 7.279999-7.599999 0-4.200004-3.26001-7.610008-7.279999-7.610008v-21.119995c16.729996 0 30.279999 14.149994 30.279999 31.599998z" />
        <path d="m90.239998 96.429993c-9.75-7.999993-16.299996-20.299996-17.199997-34.269989h-52.240002c-10.959999 0-19.84 9.009994-19.84 20.129997v25.170006c0 5.559997 4.440003 10.069992 9.920002 10.069992h69.439999c5.480003 0 9.919998-4.509995 9.919998-10.069992z" />
      </g>
      <path d="m43.160004 56.129997c-2.420006-.459999-4.820008-.889999-7.110001-1.86-8.650001-3.629998-13.690002-10.32-15.320003-19.769997-1.119999-6.470001-.590001-12.869995 2.029998-18.919998 3.720002-8.600006 10.390004-13.259995 19.150006-14.839997 5.239998-.940002 10.459999-.73001 15.5 1.149994 7.589996 2.819992 12.679992 8.259995 15.029998 16.240006 2.379998 8.049988 2.029999 16.100006-1.560005 23.720001-3.719993 7.959992-10.209999 12.229996-18.419998 13.899994-.68.139999-1.370003.269997-2.049995.410004-2.410004-.030007-4.830002-.030007-7.25-.030007z" />
    </svg>
  </span>
)
export default PasskeyIcon
