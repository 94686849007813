import { useEffect } from 'react'
import { Button, NonIdealState, Spinner } from '@blueprintjs/core'
import { AuthResponse } from '@snapauth/sdk'
import { useLocation } from 'wouter'

import { EmailInput } from 'components'
import { snapAuth, toast } from 'utilities'
import { Layout } from 'pages'
import { useApp } from 'hooks'

const verifyCode = async (code: string, app: ReturnType<typeof useApp>) => {
  const result = await app.post('/app/confirmEmail', { code })
  if (result) {
    toast.success('Email address verified - thanks!')
  } else {
    toast.warning('We were unable to process your email verification')
  }
}

const ConfirmEmail: React.FC = () => {
  const app = useApp()
  const [, setLocation] = useLocation()

  const code = window.location.hash.substring(1) // rm #

  useEffect(() => {
    if (!app.isAuthenticated) {
      return
    }
    const doVerify = async () => {
      await verifyCode(code, app)
      setLocation(`/`, { replace: true })
    }
    doVerify()
  }, [app.isAuthenticated, code])

  if (!app.isAuthenticated) {
    return <SignIn />
  }
  return <NonIdealState icon={<Spinner />} title="Verifying email address" />
}

const SignIn = () => {
  const app = useApp()
  useEffect(() => {
    snapAuth.handleAutofill(signIn)
  }, [])
  const signIn = async (auth: AuthResponse) => {
    if (auth.ok) {
      const signInResult = await app.post('/app/signin', { token: auth.data.token })
      app.signInWithoutRedirecting(signInResult.accessToken)
    }
  }

  const startSignIn = async (e: React.FormEvent) => {
    e.preventDefault()
    const fd = new FormData(e.target as HTMLFormElement)
    const email = fd.get('email')!.toString()
    const auth = await snapAuth.startAuth({ handle: email })
    signIn(auth)
  }

  return (
    <Layout.Dialog title="Sign in to confirm email">
      <form onSubmit={startSignIn}>

        <EmailInput required />
        <Button type="submit" intent="primary">Sign In</Button>
      </form>
    </Layout.Dialog>
  )
}

export default ConfirmEmail
