import { FormGroup, InputGroup } from '@blueprintjs/core'

interface Props {
  defaultValue?: string
  required?: boolean
}
const EmailInput: React.FC<Props> = ({ defaultValue, required }) => {
  return (
    <FormGroup label="Email address:">
      <InputGroup
        leftIcon="at"
        placeholder="you@example.org"
        name="email"
        type="email"
        defaultValue={defaultValue}
        autoComplete="username webauthn"
        required={required}
      />
    </FormGroup>
  )
}

export default EmailInput
