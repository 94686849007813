// TODO: maybe use dark icons in dark mode?
// import amex_d from './amex_dk.png'
import amex_l from './amex_lt.png'
// import disc_d from './disc_dk.png'
import disc_l from './disc_lt.png'
// import mast_d from './mast_dk.png'
import mast_l from './mast_lt.png'
// import visa_d from './visa_dk.png'
import visa_l from './visa_lt.png'

interface Props {
  pm: Entities.PaymentMethod
}
const PaymentMethodInfo: React.FC<Props> = ({ pm }) => {
  let icon
  if (pm.brand === 'visa') {
    icon = visa_l
  } else if (pm.brand === 'mast') {
    icon = mast_l
  } else if (pm.brand === 'disc') {
    icon = disc_l
  } else if (pm.brand === 'amex') {
    icon = amex_l
  }
  return (
    <span>
      {icon
        ? <img src={icon} alt={pm.brand} width="32" height="20" />
        : pm.brand
      } Ending {pm.last4}, Expires {pm.expiration.m}/{pm.expiration.y}
    </span>
  )

}

export default PaymentMethodInfo
