import { UL } from '@blueprintjs/core'

import { useApiData } from 'hooks'

interface Props {
  envId: EnvId
}
const GettingStarted: React.FC<Props> = ({ envId }) => {
  const env = useApiData('/app/getEnvById', { envId })
  if (!env) {
    return null
  }

  return (
    <>
      <h4>Relying Party Identifier: <code>{env.rpId}</code></h4>
      <p>
        This will work on:
      </p>
      <UL>
        {env.hosts.map(host => <li key={host}><code>{host}</code></li>)}
      </UL>
    </>
  )
}

export default GettingStarted
