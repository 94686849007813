import { Button, FormGroup, InputGroup } from '@blueprintjs/core'
import { useLocation } from 'wouter'

import { useApp } from 'hooks'
import { Layout } from 'pages'
import { toast } from 'utilities'

import './index.css'

const CreateOrg: React.FC = () => {
  const app = useApp()
  const [, updateLocation] = useLocation()
  const submit = async (e: React.FormEvent) => {
    e.preventDefault()
    const fd = new FormData(e.target as HTMLFormElement)
    const name = fd.get('name')!.toString()

    const result = await app.post('/app/createOrg', {
      name,
    })

    // Pre-create localhost for the new org
    const env = await app.post('/app/createEnv', {
      orgId: result.id,
      name: 'Local development',
      displayName: `${name} (localhost)`,
      rpId: 'localhost',
      rpType: 'wc',
    })
    toast.success('Org created')
    updateLocation(`/org/${result.id}/env/${env.envId}`)
  }
  return (
    <Layout.Dialog title="Create your Organization">
      <form onSubmit={submit}>
        <FormGroup
          label="Name"
        >
          <InputGroup
            leftIcon="globe-network"
            name="name"
            placeholder="Organization/Company Name"
            required
            type="text"
          />
        </FormGroup>
        <Button intent="success" type="submit">Create</Button>
      </form>
    </Layout.Dialog>
  )
}

export default CreateOrg
