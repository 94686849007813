interface Props {
  unixtimeSeconds: UnixtimeSeconds
}
/**
 * Despite the name, only date-only for now. More formatting options to come in
 * the future, probably
 */
const Time: React.FC<Props> = ({ unixtimeSeconds }) => {
  const parsed = new Date(unixtimeSeconds * 1000)

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#options
  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'UTC',
  }

  const display = parsed.toLocaleDateString(undefined, options)

  return (
    <time
      className="Time"
      dateTime={parsed.toISOString()}
      title={parsed.toLocaleString()}
    >{display}
    </time>
  )
}

export default Time
