import { Button, FormGroup, InputGroup, HTMLTable, Tag } from '@blueprintjs/core'

import { useApp, useApiData } from 'hooks'
import { toast } from 'utilities'

interface Props {
  orgId: OrgId
}
const Members: React.FC<Props> = ({ orgId }) => {
  const app = useApp()
  const sendInvite = async (e: React.FormEvent) => {
    e.preventDefault()

    const fd = new FormData(e.target as HTMLFormElement)
    const recipientEmail = fd.get('email')
    const data = { recipientEmail, orgId }
    const res = await app.post('/app/invite/send', data)
    if (res) {
      // Refresh the data now! FIXME: find a real method to do this.
      // Toast doesn't persist across reload, of course.
      // toaster.show({ intent: 'success', message: 'Invite sent' })
      window.location.reload()
    } else {
      toast.warning('Could not send invite')
    }
  }

  const invIds = useApiData('/app/org/inviteIds', { orgId })
  const mbrs = useApiData('/app/org/members', { orgId })

  return (
    <section>
      <h3>Current Members</h3>
      <HTMLTable>
        <thead>
          <tr>
            <th>Member</th>
            {/* <th>Role</th> */}
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {(mbrs ?? []).map(mbr => <MemberRow member={mbr} key={mbr.membershipId} />)}

          {(invIds ?? []).map(id => <InviteRow inviteId={id} key={id} />)}
        </tbody>
      </HTMLTable>

      <h3>Invite Members</h3>
      <form onSubmit={sendInvite}>
        <FormGroup
          helperText="We'll send this person an email with an invitation link"
          label="Email"
        >
          <InputGroup
            leftIcon="at"
            placeholder="person@yourorg.org"
            required
            type="email"
            name="email"
          />
        </FormGroup>
        <Button type="submit" intent="primary" icon="new-person">Send Invite</Button>
      </form>
    </section>
  )
}

const MemberRow: React.FC<{ member: Entities.Membership }> = ({ member }) => {
  return (
    <tr>
      <td>{member.userEmail}</td>
      <td><Tag round intent="success">Active</Tag></td>
    </tr>
  )
}

const InviteRow: React.FC<{ inviteId: Entities.Invite['inviteId'] }> = ({ inviteId }) => {
  const invite = useApiData('/app/invite/view', { inviteId })
  if (!invite) {
    return null
  }
  const isExpired = (invite.expiresAt * 1000) < Date.now()

  return (
    <tr>
      <td>{invite.recipientEmail}</td>
      <td><Tag round intent={isExpired ? 'danger' : 'none'}>Invite {isExpired ? 'Expired' : 'Sent'}</Tag></td>
    </tr>
  )
}

export default Members
