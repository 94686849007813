interface Props {
  title: string
}
const Section: React.FC<Props & HasChildren> = ({ children, title }) => {
  return (
    <section className="Section">
      <h3>{title}</h3>
      {children}
    </section>
  )
}

export default Section
