import React, {
  Dispatch,
  ReactNode,
  createContext,
  useEffect,
  useReducer,
} from 'react'

const STORAGE_KEY = 'API_TOKEN'

type AppAction =
  | { type: 'SIGN_IN', token: string }
  | { type: 'SIGN_OUT' }

interface AppState {
  apiToken: string | null
  // TODO: expiry?
}

interface AppContextProps {
  state: AppState
  dispatch: Dispatch<AppAction>
}

const authReducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case 'SIGN_IN':
      return { ...state, apiToken: action.token }
    case 'SIGN_OUT':
      return { apiToken: null }
    default:
      return state
  }
}

const getInitialState = (): AppState => {
  const storedApiToken = localStorage.getItem(STORAGE_KEY)
  return {
    apiToken: storedApiToken,
  }
}

export const AppContext = createContext<AppContextProps>({
  state: getInitialState(),
  dispatch: () => undefined,
})

interface AppProviderProps {
  children: ReactNode
}
const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, undefined, getInitialState)

  useEffect(() => {
    // Persist the token into local storage to power getInitialState
    if (state.apiToken === null) {
      localStorage.removeItem(STORAGE_KEY)
    } else {
      localStorage.setItem(STORAGE_KEY, state.apiToken)
    }
  }, [state.apiToken])

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  )
}

export const directlySetAccessToken = (accessToken: string) => {
  localStorage.setItem(STORAGE_KEY, accessToken)
  // Replace rather than redirect back to home. Eliminate entry from history.
  window.location.replace('/')
}

export default AppProvider
