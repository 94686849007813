import { Button } from '@blueprintjs/core'
import { useLocation } from 'wouter'

import { useApp } from 'hooks'
import { Layout } from 'pages'
import { toast } from 'utilities'

interface Props {
  invite: Entities.Invite
  code: string
}
const AcceptInviteSignedIn: React.FC<Props> = ({ invite, code }) => {
  const app = useApp()
  const [, setLocation] = useLocation()

  if (!invite) {
    return null
  }

  const acceptInvite = async () => {
    const acceptResult = await app.post('/app/invite/accept', { inviteId: invite.inviteId, code })
    if (acceptResult) {
      toast.success('Invite accepted!')
      // FIXME: stringy route
      setLocation(`/org/${acceptResult.orgId}`, { replace: true })
    } else {
      toast.warning('Invite could not be accepted')
    }
  }

  const decline = () => {
    // Just some sort of out
    if (window.confirm('Really decline?')) {
      setLocation(`/`)
    }
  }

  return (
    <Layout.Dialog title="Accept invitation?">
      <p>You've been invited to join {invite.orgName}.</p>
      <div id="AcceptInviteSignedIn">
        <Button type="button" onClick={acceptInvite} intent="success">Accept</Button>

        <Button type="button" intent="danger" onClick={decline}>Decline</Button>
      </div>
    </Layout.Dialog>
  )
}

export default AcceptInviteSignedIn
