import React from 'react'
import ReactDOM from 'react-dom/client'

import AppProvider, { directlySetAccessToken } from 'contexts/AppContext'
import App from './App'
import * as config from './config'

import '@blueprintjs/core/lib/css/blueprint.css'
import './index.css'
import './variables.css'

// Magic link from www site detection. Run it before routing.
if (window.location.pathname === '/sign-in-from-fragment') {
  const accessToken = window.location.hash.substring(1) // Remove leading #
  directlySetAccessToken(accessToken)
}

const StatsScript: React.FC = () => {
  React.useEffect(() => {
    const script = document.createElement('script')
    script.src = config.API_HOST + '/stats.js'
    script.defer = true
    script.setAttribute('data-key', config.SDK_PUBLISHABLE_KEY)
    script.setAttribute('data-dedupe', '')
    document.body.appendChild(script)
    return () => { document.body.removeChild(script) }
  }, [])
  return null
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <AppProvider>
      <App />
      <StatsScript />
    </AppProvider>
  </React.StrictMode>
)
