import { H2, Card } from '@blueprintjs/core'

import { Primary } from '../'
import './index.css'

/**
 * Creates a single centered element that's still in the main layout. It is not
 * modal and does not impose styling on the rest of the page.
 */
interface Props {
  id?: string
  title: string
}
const Dialog: React.FC<Props & HasChildren> = ({ children, id, title }) => {
  return (
    <Primary id="Dialog" disableNavigation>
      <Card id={id}>
        <H2>{title}</H2>
        <main>{children}</main>
      </Card>
    </Primary>
  )
}
export default Dialog
