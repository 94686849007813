import './index.css'

/**
 * Global nav footer. If this expands, adjust the footer-height var in
 * variables.css to accomodate.
 */
const Footer: React.FC = ( ) => (
  <footer id="Footer">
    <nav>
      <h4>Contact us</h4>
      <ul>
        <li>
          <a href="mailto:hello@snapauth.app">hello@snapauth.app</a>
        </li>
        <li>
          <a href="https://snapauth.freshdesk.com" target="_blank" rel="noopener noreferrer">Support</a>
        </li>
      </ul>
    </nav>
  </footer>
)
export default Footer
