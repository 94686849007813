import { Language } from './samples'

import hljs from 'highlight.js/lib/core'
// There doesn't seem to be a nicer way to do bulk importing. Oh well.
import bash from 'highlight.js/lib/languages/bash'
import xml from 'highlight.js/lib/languages/xml'
import php from 'highlight.js/lib/languages/php'
import typescript from 'highlight.js/lib/languages/typescript'

// Dealing with dark mode conditionally will be annoying with this. Instead,
// we'll likely have to copy-paste the contents and switch it to variables.
import 'highlight.js/styles/base16/solarized-light.min.css'
import './Snippet.css'

hljs.registerLanguage('bash', bash)
hljs.registerLanguage('html', xml)
hljs.registerLanguage('php', php)
hljs.registerLanguage('typescript', typescript)

interface Props {
  lang: Language // does this line up??
  children: string
}
const Snippet: React.FC<Props> = ({ lang, children }) => {
  const highlighted = hljs.highlight(children, { language: lang }).value
  return <code className="Snippet"><pre dangerouslySetInnerHTML={{__html: highlighted}} /></code>
}

export default Snippet
