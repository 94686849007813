import { useState, useEffect } from 'react'

import { useApp } from 'hooks'

enum LoadState {
  NotStarted,
  Loading,
  Success,
  Error,
}
type Result<T> =
  | { s: LoadState.NotStarted }
  | { s: LoadState.Loading }
  | { s: LoadState.Error }
  | { s: LoadState.Success, data: T }
/**
 * This aims to be a type-safe (ish) API data wrapper, for anything that would
 * functionally be a GET request (actual wire formats notwithstanding). It
 * interacts with the AppContext to cache responses so they only get loaded
 * once regardless of how many times they're requested via hooks. Funtionally
 * treat it as UNIQUE(route, params).
 *
 * It will return `undefined` for ANY non-ideal state: loading, load failed,
 * not yet started, etc.
 */
const useApiData = <
  TUrl extends Queries.AnyQueryUrl,
  TQuery extends Queries.AnyQuery = Extract<Queries.AnyQuery, { url: TUrl }>,
>(url: TUrl, params: TQuery['params']): TQuery['result'] | undefined=> {
  const app = useApp()
  const [result, setResult] = useState<Result<TQuery['result']>>({s: LoadState.NotStarted})

  // FIXME: make this a) normalized and b) more compact
  // const query = JSON.stringify(params)

  useEffect(() => {
    if (result.s !== LoadState.NotStarted) {
      // Already started
      return
    }
    const loadIt = async () => {
      setResult({ s: LoadState.Loading })
      const result = await app.post(url, params)
      if (result) {
        setResult({ s: LoadState.Success, data: result })
        // app.processApiResponseOnlyUseThisInHooks(url, query, result)
      } else {
        // TODO: error case? handle in post()?
        // For now, throw in NULL just so it doesn't infinite loop on the
        // undefined check above
        // app.processApiResponseOnlyUseThisInHooks(url, query, null)
        setResult({ s: LoadState.Error })
      }
    }
    loadIt()
  }, [result, url, params])

  return result.s === LoadState.Success ? result.data : undefined
}
export default useApiData
