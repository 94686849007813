import { AnchorButton, NonIdealState } from '@blueprintjs/core'
import { useApiData, useApp } from 'hooks'

import AcceptInviteSignedIn from './SignedIn'
import AcceptInviteSignedOut from './SignedOut'
import './index.css'

interface Props {
  inviteId: Entities.Invite['inviteId']
}
const AcceptInvite: React.FC<Props> = ({ inviteId }) => {
  const app = useApp()
  const code = window.location.hash.substring(1) // remove leading #

  const invite = useApiData('/app/invite/view', { code, inviteId })
  if (!invite) {
    return <NonIdealState
      icon="warning-sign"
      title="Invitation Not found"
      description="The invitation you're trying to view may not exist or could have expired."
      action={<AnchorButton href="/" intent="primary">Go Home</AnchorButton>}
    />
  }

  if (app.isAuthenticated) {
    return <AcceptInviteSignedIn {...{code, invite}} />
  } else {
    return <AcceptInviteSignedOut {...{invite}} />
  }
}
export default AcceptInvite
