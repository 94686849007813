import {
  Breadcrumb,
  Breadcrumbs,
  BreadcrumbProps,
  Button,
  Navbar,
} from '@blueprintjs/core'
import { Link } from 'wouter'

import Footer from '../Footer'

import { ActiveLink } from 'components'
import { useApp } from 'hooks'

import logo from 'assets/logo.svg'
import './index.css'

interface Props {
  id?: string
  disableNavigation?: boolean
  breadcrumbs?: BreadcrumbProps[]
  secondLevelNav?: React.ReactNode
}
const Primary: React.FC<Props & HasChildren> = ({ breadcrumbs, children, disableNavigation = false, id, secondLevelNav }) => {
  const app = useApp()

  const breadcrumbRenderer = (props: BreadcrumbProps) => {
    const { href, ...breadcrumbProps } = props
    if (!href) {
      return <Breadcrumb {...props} />
    }
    return <Link asChild href={href}><Breadcrumb {...breadcrumbProps} /></Link>
  }

  if (disableNavigation) {
    return <div id="Primary">
      <nav>
        <div className="leading">
          <Link href="~/" id="PrimaryLogoLink">
            <img src={logo} id="PrimaryLogo" alt="" />
          </Link>
        </div>
      </nav>
      <main id={id}>{children}</main>
      <Footer />
    </div>
  }

  return (
    <div id="Primary">
      <nav>
        <div className="leading">
          <Link href="~/" id="PrimaryLogoLink">
            <img src={logo} id="PrimaryLogo" alt="" />
          </Link>
          <Breadcrumbs breadcrumbRenderer={breadcrumbRenderer} items={[
            { href: '~/', icon: 'home', iconTitle: 'Home' },
            ...breadcrumbs || [],
          ]} />
        </div>

        <div className="trailing">
          <ActiveLink href="~/settings" icon="cog" minimal title="Settings" />
          <Navbar.Divider />
          <Button onClick={app.signOut} intent="danger" icon="log-out">Sign Out</Button>
        </div>

        <div className="secondary">{secondLevelNav}</div>
      </nav>

      <main id={id}>{children}</main>

      <Footer />
    </div>
  )
}

export default Primary
